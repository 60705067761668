<template>
  <el-dialog title="产品工艺标管控卡" :visible.sync="selfShow" :close-on-click-modal="false">
    <ItemTestRule :isMaterial="false" :data.sync="selfData" :disabled="disabled" />
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ItemTestRule from './item-test-rule'
export default {
  components: {
    ItemTestRule
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {}
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.popupCancel()
      if (JSON.stringify(this.data) !== JSON.stringify(this.selfData)) {
        this.$emit('update:data', this.$util.deepCopy(this.selfData))
      }
    }
  }
}
</script>

<style></style>
