import XEUtils from 'xe-utils'
import util from '@/utils/util'
import tool from '@/utils/tool'

// 计算项 数量 = 每套零件数量 * 父级数量
export function rowCounts(row, parent) {
  let a = Number(row.counts_unit || 1)
  let b = parent ? Number(parent.counts) : Number(row.counts)

  let result = XEUtils.multiply(a, b)
  row.counts = util.getPoint(result, 'up', 0) // 保留两位小数向上取整
  // 加工数量 = 父级数量
  row.process_list.forEach((item) => {
    item.process_count = row.counts
  })
  util.consoleLog('row', '数量', 'counts', row.counts)
}

// 计算项 零件长度 = 规格参数末尾星号数值
export function rowPartsLength(row) {
  let arr = row.sales_specification.split('*')

  let result = ''
  let isEffict = arr.every((item) => {
    return !!Number(item)
  })
  if (isEffict && (arr.length === 3 || arr.length === 4)) {
    result = Number(arr[arr.length - 1])
  }

  row.parts_length = util.getPoint(result, 'up', 2) // 保留两位小数向上取整

  util.consoleLog('row', '零件长度', 'parts_length', row.parts_length)
}

// 计算项 尾料长度 = 原料长度 % 零件长度 取余数
export function rowTailingLength(row) {
  let a = Number(row.material_length) * 10000
  let b = Number(row.parts_length) * 10000

  let result = ''
  if (a && b) result = (a % b) / 10000

  row.tailing_length = util.getPoint(result, 'down', 2) // 保留两位小数向上取整
  util.consoleLog('row', '尾料长度', 'tailing_length', row.tailing_length)
}

// 计算项 支重
export function rowSingleWeight(row) {
  let midu = 0.02491 // 材质密度
  let pai = 3.1415926 // π
  let len = row.material_length // 原料长度
  let arr = row.sales_specification.split('*') // 规格
  let result = '' // 支重

  let isEffict = arr.every((item) => {
    return !!Number(item)
  })
  if (isEffict && arr.length === 3) {
    /**
     * 圆管 12*2*380 [12, 2, 380]
     * 支重 = (12 - 2) * 2 * 6 * 0.02491 = 2.9892
     * 支重 = (外径 - 厚度) * 厚度 * 原料长度 / 1000 * 材质密度
     */
    let n1 = Number(arr[0]) // 外径
    let n3 = Number(arr[1]) // 厚度

    result = XEUtils.multiply(
      XEUtils.divide(XEUtils.multiply(XEUtils.multiply(XEUtils.subtract(n1 - n3), n3), len), 1000),
      midu
    )
  } else if (isEffict && arr.length === 4) {
    /**
     * 方管 12*12*2*400 [12, 12, 2, 400]
     * 矩管 12*10*2*400 [12, 10, 2, 400]
     * 支重 = ((12 + 12) * 2 / 3.1415926 - 2) * 2 * 6000 / 1000 * 0.02491 = 3.9693
     * 支重 = ((12 + 10) * 2 / 3.1415926 - 2) * 2 * 6000 / 1000 * 0.02491 = 3.5887
     * 支重 = ((边长 + 边宽) * 2 / π - 厚度) * 厚度 * 原料长度 / 1000 * 材质密度
     */
    let n1 = Number(arr[0])
    let n2 = Number(arr[1])
    let n3 = Number(arr[2])

    result = XEUtils.multiply(
      XEUtils.divide(
        XEUtils.multiply(
          XEUtils.multiply(
            XEUtils.subtract(XEUtils.divide(XEUtils.multiply(XEUtils.add(n1 + n2), 2), pai), n3),
            n3
          ),
          len
        ),
        1000
      ),
      midu
    )
  }

  row.single_weight = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '支重', 'single_weight', row.single_weight)
}

// 计算项 材料单价 = 支重 * 今日基价
export function rowMaterialPrice(row) {
  if (row.is_lock_material_price) return // 是否锁定材料单价

  let a = Number(row.single_weight)
  let b = Number(row.today_base_price)

  let result = XEUtils.multiply(a, b)

  row.material_price = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '材料单价', 'material_price', row.material_price)
}

// 计算项 每支可切数量 = (原料长度 - 尾料长度) / 零件长度 向下取整
export function rowPartsCount(row) {
  let a = XEUtils.subtract(row.material_length, row.tailing_length)
  let b = Number(row.parts_length)

  let result = ''
  if (a && b) result = XEUtils.divide(a, b)

  row.parts_count = util.getPoint(result, 'down', 0)
  util.consoleLog('row', '每支可切数量', 'parts_count', row.parts_count)
}

// 计算项 所需材料数量 = 数量 / 每支可切数量 向上取整
export function rowMaterialCounts(row) {
  if (row.is_lock_material_counts) return // 是否锁定所需材料数量

  let a = Number(row.counts)
  let b = Number(row.parts_count)

  let result = ''
  if (a && b) result = XEUtils.divide(a, b)

  row.material_counts = util.getPoint(result, 'up', 0)
  util.consoleLog('row', '所需材料数量', 'material_counts', row.material_counts)
}

// 计算项 所需材料成本 = 材料单价 * 所需材料数量
export function rowMaterialSalesAmount(row) {
  let a = Number(row.material_price)
  let b = Number(row.material_counts)

  let result = ''
  if (a && b) result = XEUtils.multiply(a, b)

  row.material_sales_amount = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '所需材料成本', 'material_sales_amount', row.material_sales_amount)
}

// 计算项 每件材料成本 = 所需材料成本 / 数量
export function rowCostPriceUnit(row) {
  let a = Number(row.material_sales_amount)
  let b = Number(row.counts)

  let result = ''
  if (a && b) result = XEUtils.divide(a, b)

  row.cost_price_unit = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '每件材料成本', 'cost_price_unit', row.cost_price_unit)
}

// 计算项 加工行加工金额 = 加工单价 * 数量
export function rowProcessListProcessAmount(row) {
  row.process_list.forEach((item) => {
    let a = Number(item.process_price)
    let b = Number(item.process_count)

    let result = ''
    if (a && b) result = XEUtils.multiply(item.process_price, item.process_count)
    item.process_amount = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  })
}

// 计算项 每件加工组装 = 加工单价总计
export function rowProcessPriceUnit(row) {
  row.process_price_unit = tool.listAddTotal(row.process_list, 'process_price')
  util.consoleLog('row', '每件加工组装', 'process_price_unit', row.process_price_unit)
}

// 计算项 所需加工组装 = 加工金额总计
export function rowProcessPrice(row) {
  row.process_price = tool.listAddTotal(row.process_list, 'process_amount')
  util.consoleLog('row', '所需加工组装', 'process_price', row.process_price)
}

// 计算项 每件成本 = (每件材料成本 + 每件加工组装) * 每套零件数量
export function rowTotalCost(row) {
  let a = Number(row.cost_price_unit)
  let b = Number(row.process_price_unit)
  let c = Number(row.counts_unit)

  let result = XEUtils.multiply(XEUtils.add(a, b), c)
  return util.getPoint(result, 'up', 2) // 保留两位小数向上取整
}

// 计算项 销售金额 = 销售单价 * 数量
export function rowSalesAmount(row) {
  let result = XEUtils.multiply(row.sales_price, row.counts)
  row.sales_amount = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '销售金额', 'sales_amount', row.sales_amount)
}

// 计算项 每件利润 = 销售单价 - (每件材料成本 + 每件加工组装)
export function rowProfitRowUnit(row) {
  let a = Number(row.sales_price)
  let b = Number(row.cost_price_unit)
  let c = Number(row.process_price_unit)

  let result = XEUtils.subtract(a, XEUtils.add(b, c))
  row.profit_row_unit = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '每件利润', 'profit_row_unit', row.profit_row_unit)
}

// 计算项 合计利润 = 每件利润 * 数量
export function rowProfitRow(row) {
  let result = XEUtils.multiply(row.profit_row_unit, row.counts)
  row.profit_row = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '合计利润', 'profit_row', row.profit_row)
}

// 计算项 利润率 = 合计利润 / 销售金额 * 100
export function rowProfitRate(row) {
  let a = Number(row.profit_row)
  let b = Number(row.sales_amount)

  let result = ''
  if (a && b) result = XEUtils.multiply(XEUtils.divide(a, b), 100)
  row.profit_rate = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('row', '利润率', 'profit_rate', row.profit_rate)
}

// 计算项 订单销售金额 = 销售金额总计
export function formSalesAmountTotal(detail) {
  let result = tool.listAddTotal(detail.tableSaleData, 'sales_amount')
  detail.formData.sales_amount_total = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('form', '订单销售金额', 'sales_amount_total', detail.formData.sales_amount_total)
}

// 计算项 税金 = 税率 / 100 * 订单销售金额
export function formTaxFeesTotal(detail) {
  let a = Number(detail.formData.bill_tax_rate)
  let b = Number(detail.formData.sales_amount_total)

  let result = XEUtils.multiply(XEUtils.divide(a, 100), b)
  detail.formData.tax_fees_total = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('form', '税金', 'tax_fees_total', detail.formData.tax_fees_total)
}

// 计算项 费用合计 = 销售运费 + 打包费 + 其他费用 + 税金
export function formFeesTotal(detail) {
  let { freight_fees_bill, pack_fees_bill, other_fees_bill, tax_fees_total } = detail.formData
  let arr = [
    Number(freight_fees_bill),
    Number(pack_fees_bill),
    Number(other_fees_bill),
    Number(tax_fees_total)
  ]

  let result = tool.listAddTotal(arr)
  detail.formData.fees_total = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('form', '费用合计', 'fees_total', detail.formData.fees_total)
}

// 计算项 订单成本 = 所需材料成本总计 + 所需加工组装总计 + 费用合计
export function formCostPriceTotal(detail) {
  let a = tool.listAddTotal(detail.tableSaleData, 'material_sales_amount')
  let b = tool.listAddTotal(detail.tableSaleData, 'process_price')
  let c = Number(detail.formData.fees_total)
  let arr = [a, b, c]
  let result = tool.listAddTotal(arr)
  detail.formData.cost_price_total = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('form', '订单成本', 'cost_price_total', detail.formData.cost_price_total)
}

// 计算项 订单利润 = 订单销售金额 - 订单成本
export function formProfitTotal(detail) {
  let a = Number(detail.formData.sales_amount_total)
  let b = Number(detail.formData.cost_price_total)

  let result = XEUtils.subtract(a, b)
  detail.formData.profit_total = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('form', '订单利润', 'profit_total', detail.formData.profit_total)
}

// 计算项 订单利润率 = 订单利润 / 订单销售金额 * 100
export function formProfitRate(detail) {
  let a = Number(detail.formData.profit_total)
  let b = Number(detail.formData.sales_amount_total)

  let result = ''
  if (a && b) result = XEUtils.multiply(XEUtils.divide(a, b), 100)
  detail.formData.profit_rate_total = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('form', '订单利润率', 'profit_rate_total', detail.formData.profit_rate_total)
}

// 计算项 提成金额 = 订单利润 * 提成比例
export function formPushMoney(detail) {
  let a = Number(detail.formData.profit_total)
  let b = Number(detail.formData.commission_rate)

  let result = XEUtils.divide(XEUtils.multiply(a, b), 100)
  detail.formData.push_money = util.getPoint(result, 'up', 2) // 保留两位小数向上取整
  util.consoleLog('form', '提成金额', 'push_money', detail.formData.push_money)
}

// 计算项 汇总行材料
export function totalRowMaterialData(row) {
  // 兼容板材等自定义输入，不参与某些计算
  if (row.category === '其他' && !row.tableBomData.length) return

  rowPartsLength(row) // 计算-零件长度
  rowTailingLength(row) // 计算-尾料长度
  rowSingleWeight(row) // 计算-支重
  if (!row.is_lock_material_price) rowMaterialPrice(row) // 计算-材料单价
  rowPartsCount(row) // 计算-每支可切数量
  if (!row.is_lock_material_counts) rowMaterialCounts(row) // 计算-所需材料数量
  rowMaterialSalesAmount(row) // 计算-所需材料成本
  rowCostPriceUnit(row) // 计算-每件材料成本
}

// 计算项 汇总行加工组装
export function totalRowProcessData(row) {
  rowProcessListProcessAmount(row) // 计算-加工行加工金额
  rowProcessPriceUnit(row) // 计算-每件加工组装
  rowProcessPrice(row) // 计算-所需加工组装
}

// 计算项 汇总行销售
export function totalRowSaleData(row) {
  rowSalesAmount(row) // 计算-销售金额
  rowProfitRowUnit(row) // 计算-每件利润
  rowProfitRow(row) // 计算-合计利润
  rowProfitRate(row) // 计算-利润率
}

// 计算项 汇总行
export function totalRowData(row) {
  totalRowMaterialData(row) // 计算-汇总行材料
  totalRowProcessData(row) // 计算-汇总行加工组装
  totalRowSaleData(row) // 计算-汇总行销售

  util.consoleLog('<<<<<<<<<<row', row)
}

// 计算项 汇总表格-递归
export function totalTableData(list) {
  let result = ''
  list.forEach((row) => {
    if (row.tableBomData.length) {
      row.cost_price_unit = totalTableData(row.tableBomData) // 每件材料成本
      row.material_sales_amount = XEUtils.multiply(row.cost_price_unit, row.counts) // 所需材料成本
      totalRowSaleData(row) // 计算-汇总行销售
    } else {
      totalRowData(row) // 计算-汇总行
    }
    result = XEUtils.add(result, rowTotalCost(row))
  })
  return result
}

// 计算项 汇总表单数据
export function totalFormData(detail) {
  formSalesAmountTotal(detail) // 计算-订单销售金额
  formTaxFeesTotal(detail) // 计算-税金
  formFeesTotal(detail) // 计算-费用合计
  formCostPriceTotal(detail) // 计算-订单成本
  formProfitTotal(detail) // 计算-订单利润
  formProfitRate(detail) // 计算-订单利润率
  formPushMoney(detail) // 计算-提成金额
}

// 计算项 汇总详情数据
export function totalDetailData(detail) {
  totalTableData(detail.tableSaleData)
  totalFormData(detail)

  util.consoleLog('----------------------------------------detailData', detail)
  return detail
}

const math = {
  rowCounts, // 数量

  // 行材料
  // rowPartsLength, // 零件长度
  // rowTailingLength, // 尾料长度
  // rowSingleWeight, // 支重
  // rowMaterialPrice, // 材料单价
  // rowPartsCount, // 每支可切数量
  // rowMaterialCounts, // 所需材料数量
  // rowMaterialSalesAmount, // 所需材料成本
  // rowCostPriceUnit, // 每件材料成本

  // 行加工组装
  // rowProcessListProcessAmount, // 加工行加工金额
  // rowProcessPriceUnit, // 每件加工组装
  // rowProcessPrice, // 所需加工组装

  // 行销售
  // rowSalesAmount, // 销售金额
  // rowProfitRowUnit, // 每件利润
  // rowProfitRow, // 合计利润
  // rowProfitRate, // 利润率

  // 表单
  // formSalesAmountTotal, // 订单销售金额
  // formTaxFeesTotal, // 税金
  // formFeesTotal, // 费用合计
  // formCostPriceTotal, // 订单成本
  // formProfitTotal, // 订单利润
  // formProfitRate, // 订单利润率
  // formPushMoney, // 提成金额

  // 汇总
  totalRowMaterialData, // 汇总行材料
  totalRowProcessData, // 汇总行加工组装
  totalRowSaleData, // 汇总行销售
  totalRowData, // 汇总行
  totalTableData, // 汇总表格
  totalFormData, // 汇总表单
  totalDetailData // 汇总详情
}
export default math
