<template>
  <div class="box-test-rule">
    <div v-if="getParentTableObj()" :class="{ 'm-t-20': isMaterial, 'm-b-10': true }">
      <span class="m-r-20">产品工艺流程：{{ isMaterial ? '原材料' : selfData.process_name }}</span>
      <span v-if="!isMaterial" class="m-r-20"> 材质：{{ getParentTableObj().texture }} </span>
      <span v-if="!isMaterial" class="m-r-20">
        规格：{{ getParentTableObj().sales_specification }}
      </span>
    </div>
    <vxe-grid
      ref="refTable"
      :size="tableConfig.size"
      :border="tableConfig.border"
      :min-height="tableConfig.minHeight"
      :header-cell-class-name="cellClassName"
      :edit-config="tableConfig.editConfig"
      :row-config="tableConfig.rowConfig"
      :mouse-config="tableConfig.mouseConfig"
      :keyboard-config="tableConfig.keyboardConfig"
      :edit-rules="validRules"
      :columns="tableColumn"
      :data="selfData.test_list"
    >
      <!-- 检测项目 -->
      <template #edit_test_name="{ row }">
        <el-input v-model="row.test_name" type="text" size="mini" clearable />
      </template>

      <!-- 检测方法 -->
      <template #edit_test_action="{ row }">
        <el-input v-model="row.test_action" type="text" size="mini" clearable />
      </template>

      <!-- 检测标准 -->
      <template #edit_test_rule="{ row }">
        <el-input v-model="row.test_rule" type="text" size="mini" clearable />
      </template>

      <!-- 检测比例 -->
      <template #edit_test_scale="{ row }">
        <el-input v-model="row.test_scale" type="text" size="mini" clearable />
      </template>

      <!-- 参考标准 -->
      <template #edit_reference_standard="{ row }">
        <el-input v-model="row.reference_standard" type="text" size="mini" clearable />
      </template>

      <!-- 备注 -->
      <template #edit_remark="{ row }">
        <el-input v-model="row.remark" type="text" size="mini" clearable />
      </template>

      <!-- 操作 -->
      <template #default_do="{ rowIndex }">
        <el-link class="m-r-10" type="success" :disabled="disabled" @click="rowAdd">增行</el-link>
        <el-link
          v-if="selfData.test_list.length > 1"
          class="m-r-10"
          type="danger"
          :disabled="disabled"
          @click="rowDelete(rowIndex)"
          >删行</el-link
        >
      </template>

      <!-- 数据为空 -->
      <template #empty>
        <div class="flex flex-center">
          <span class="f-s-14 c-gray-light">暂无数据</span>
          <el-link class="m-l-10" type="success" :disabled="disabled" @click="rowAdd">增行</el-link>
        </div>
      </template>
    </vxe-grid>

    <div class="m-t-20 m-b-10">图纸</div>
    <e-upload-image
      v-if="isMaterial && selfData.material_photo_list"
      :list.sync="selfData.material_photo_list"
      isDelete
      :disabled="disabled"
    />
    <e-upload-image
      v-if="!isMaterial && selfData.picture_list"
      isDelete
      :list.sync="selfData.picture_list"
      :disabled="disabled"
    />

    <div class="m-t-20 m-b-10">附言</div>
    <el-input
      type="textarea"
      size="mini"
      :disabled="disabled"
      v-model="selfData.picture_text"
      placeholder="请输入"
    />
  </div>
</template>

<script>
export default {
  inject: ['getParentTableObj'],
  props: {
    isMaterial: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      validRules: {
        // test_name: [{ required: true }],
        // test_action: [{ required: true }],
        // test_rule: [{ required: true }],
        // test_scale: [{ required: true }]
      },
      tableColumn: [
        {
          title: '序号',
          type: 'seq'
        },
        {
          title: '检测项目',
          field: 'test_name',
          slots: { edit: 'edit_test_name' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '检测方法',
          field: 'test_action',
          slots: { edit: 'edit_test_action' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '检测标准',
          field: 'test_rule',
          slots: { edit: 'edit_test_rule' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '检测比例',
          field: 'test_scale',
          slots: { edit: 'edit_test_scale' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '参考标准',
          field: 'reference_standard',
          slots: { edit: 'edit_reference_standard' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '备注',
          field: 'remark',
          slots: { edit: 'edit_remark' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '操作',
          slots: { default: 'default_do' }
        }
      ],
      selfData: {}
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        rowConfig: {
          isHover: false,
          isCurrent: false
        },
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    },
    selfData: {
      handler(val) {
        if (JSON.stringify(this.data) !== JSON.stringify(val)) {
          this.$emit('update:data', this.$util.deepCopy(val))
        }
      },
      deep: true
    }
  },
  mounted() {
    if (!this.selfData.test_list.length) this.rowAdd() // 数据为空，默认补一行
  },
  methods: {
    // 表格单元格样式设置
    cellClassName({ column }) {
      if (column.slots && column.slots.edit) return 'edit'

      return null
    },
    // 行-增行
    rowAdd() {
      let result = this.$util.deepCopy(this.$constant.tableTestObj)
      this.selfData.test_list.push(result)
      this.$refs.refTable.clearCurrentRow()
    },
    // 行-删行
    rowDelete(index) {
      this.selfData.test_list.splice(index, 1)
      this.$refs.refTable.clearCurrentRow()
    }
  }
}
</script>

<style lang="less" scoped>
.box-test-rule {
}
</style>
